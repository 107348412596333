import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// router setup
import router from './routes';
import axios from 'axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import {setCookie, getCookie, delCookie} from '@/assets/cookie'
Vue.prototype.$cookieStore = {
  setCookie,
  getCookie,
  delCookie
}
import isMobile from './static/isMobile'
Vue.prototype.$isMobile = isMobile

axios.defaults.baseURL = '/backend';//'/backend/api';
axios.defaults.withCredentials = true;
Vue.prototype.axios = axios

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
