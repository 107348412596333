
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import UserIndexLayout from '@/views/Layout/UserIndexLayout.vue';

//pc
const AiIndex = () => import('@/views/Pages/indexPage.vue');
const AiDetail = () => import('@/views/Pages/webAppDetailPage/detailPage.vue');
const LimTools = () => import('@/views/Pages/limTools/limToolsPage.vue');

//mobile
const AiDetailMobile = () => import('@/views/Pages/webAppDetailPage/detailPageMobile.vue');
const LimToolsMobile = () => import('@/views/Pages/limTools/limToolsPageMobile.vue');

//weixin
const AiIndexWx = () => import('@/views/Pages/indexPageWx.vue');
const LimToolsWx = () => import('@/views/Pages/limTools/limToolsPageWx.vue');

//Chat Test
const ChatIndexTest = () => import('@/views/Pages/indexPageTest.vue');
const ChatIndexTestAlex =  () => import('@/views/Pages/indexPageTestAlex.vue');

//For Dingdang English
const ChatIndexDingdang = () => import('@/views/Pages/indexPageDingdang.vue');

const routes = [
    {
      path: '/',
      component: UserIndexLayout,
      redirect: '/index',
      name: 'index',
      children: [
        {
          path: '/index',
          name: 'Ai7788.CN',
          component: AiIndex,
          meta: {
            keepAlive: true
          }
        },{
          path: '/webdetail',
          name: 'Ai7788.CN',
          component: AiDetail,
          meta: {
            keepAlive: false
          }
        },{
          path: '/limtools',
          name: 'Ai7788.CN',
          component: LimTools,
          meta: {
            keepAlive: false
          }
        },{
          path: '/mobile/webdetail',
          name: 'Ai7788.CN',
          component: AiDetailMobile,
          meta: {
            keepAlive: false
          }
        },{
          path: '/mobile/limtools',
          name: 'Ai7788.CN',
          component: LimToolsMobile,
          meta: {
            keepAlive: false
          }
        },{
          path: '/wx',
          name: 'wxtools',
          component: AiIndexWx,
          meta: {
            title: '状元工具箱',
            keepAlive: true
          }
        },{
          path: '/wx/limtools',
          name: 'wxlim',
          component: LimToolsWx,
          meta: {
            title: '状元工具箱',
            keepAlive: false
          }
        },
        // {
        //   path: '/index',
        //   name: 'index',
        //   component: ChatIndexTest,
        //   meta: {
        //     title: 'HHLChat',
        //     keepAlive: true
        //   }
        // },
        // {
        //   path: '/dingdang',
        //   name: 'dingdang',
        //   component: ChatIndexDingdang,
        //   meta: {
        //     title: 'DingdangEnglish',
        //     keepAlive: true
        //   }
        // },
        // {
        //   path: '/index',
        //   name: 'index',
        //   component: ChatIndexTestAlex,
        //   meta: {
        //     title: 'Test',
        //     keepAlive: true
        //   }
        // }
      ]
    },
    { path: '*', component: NotFound }
  ];
  
  export default routes;