<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
              <img src="@/assets/404.jpg" class="img-muted" @click="backhome"/>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'not-found',
  data() {
      return {
       
      };
    },
    methods: {
      backhome(){
        this.$router.push({path: '/index'});
      }
    },
};
</script>
<style scoped>
  .img-muted { 
    width: 100%;
    height:100%;
  }
</style>